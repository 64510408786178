<section class="section" [ngStyle]="{background: backgroundImage}">
    <main [ngClass]="{main: theme === 'light', mainDark: theme === 'dark'}">
        <img [src]="logoUrl" [ngClass]="{logo: logoUrl !== '/assets/logos/logo.svg'}" alt="logo" />
        <div class="headingBox">
            <div [ngClass]="{heading: theme === 'light', headingDark: theme === 'dark'}">Welcome Back!</div>
            <div class="subHead">Login to your Account</div>
        </div>
        <form class="form" novalidate>
            <div class="formUpper">
                <div class="formBoxes">
                    <div class="formBoxesInner">
                        <div class="inputOuter">
                            <div class="label">Email<span class="mandatory">*</span></div>
                            <div class="emailInput">
                                <input
                                    disabled
                                    [ngClass]="{emailInputBox: theme === 'light', emailInputBoxDark: theme === 'dark'}"
                                    placeholder="admin@demo.com"
                                    type="email"
                                    name="email"
                                    autocomplete="off" />
                            </div>
                            <div class="emailError">
                                <span></span>
                            </div>
                        </div>
                        <div class="inputOuter">
                            <div class="label">Password<span class="mandatory">*</span></div>
                            <div class="passwordInput">
                                <input
                                    disabled
                                    [ngClass]="{
                                        passwordInputBox: theme === 'light',
                                        passwordInputBoxDark: theme === 'dark'
                                    }"
                                    placeholder="********"
                                    type="password"
                                    name="password"
                                    autocomplete="off" />
                                <button>
                                    <svg
                                        width="24"
                                        height="25"
                                        viewBox="0 0 24 25"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            d="M19.439 16.2133C20.3636 15.2955 21.0775 14.3834 21.544 13.7293C21.848 13.303 22 13.0898 22 12.7743C22 12.4588 21.848 12.2456 21.544 11.8193C20.1779 9.9037 16.6892 5.77426 12 5.77426C11.0922 5.77426 10.2294 5.92902 9.41827 6.19253M6.74742 7.52168C4.73118 8.88146 3.24215 10.7169 2.45604 11.8193C2.15201 12.2456 2 12.4588 2 12.7743C2 13.0898 2.15201 13.303 2.45604 13.7293C3.8221 15.6449 7.31078 19.7743 12 19.7743C13.9908 19.7743 15.7651 19.03 17.2526 18.0269"
                                            stroke="#C2C7D0"
                                            stroke-width="1.5"
                                            stroke-linecap="round"
                                            stroke-linejoin="round" />
                                        <path
                                            d="M9.85786 10.7743C9.32783 11.3043 9 12.0366 9 12.8454C9 14.463 10.3113 15.7743 11.9289 15.7743C12.7377 15.7743 13.47 15.4465 14 14.9164"
                                            stroke="#C2C7D0"
                                            stroke-width="1.5"
                                            stroke-linecap="round" />
                                        <path
                                            d="M3 3.77426L21 21.7743"
                                            stroke="#C2C7D0"
                                            stroke-width="1.5"
                                            stroke-linecap="round"
                                            stroke-linejoin="round" />
                                    </svg>
                                </button>
                            </div>
                            <div class="emailError">
                                <span></span>
                            </div>
                        </div>
                    </div>
                    <div class="forgotBox">
                        <div class="rememberOuter">
                            <input
                                type="checkbox"
                                id="remember"
                                [ngStyle]="{'border-color': primaryColor, 'background-color': 'transparent'}"
                                checked />
                            <label for="remember" class="remember">Remember me</label>
                        </div>
                        <button class="forgotPassword" [ngStyle]="{color: primaryColor}">Forgot password?</button>
                    </div>
                </div>
            </div>
            <div class="d-grid">
                <button type="submit" class="submitBtn" [ngStyle]="{background: primaryColor}">
                    <span class="indicator-label">Login</span>
                </button>
            </div>
        </form>
        <div class="signInBox">
            <div class="remember">Are you new?</div>
            <button class="forgotPassword" [ngStyle]="{color: primaryColor}">Sign up</button>
        </div>
    </main>
</section>
